<template>
  <section id="section-testimonial" v-scrollanimation="'up'">
    <div class="nstra-container">
      <div class="section-header">
        <h3  class="section-title">
          Why clients love working
          <span class="text-primary">with us.</span>
        </h3>
      </div>
      <div class="section-body">
        <div class="testimonial-slider">
          <vue-slick-carousel v-bind="settings">
            <div class="testimonial-item" v-for="(item, index) in testimonials" :key="index">
              <div class="card card-testimonial">
                <div class="card-header">
                  <div class="d-flex align-items-center">
                    <div class="card-img-wrap">
                      <img width="75" height="75" :src="item.image" :alt="item.name" class="card-img"/>
                    </div>
                    <div>
                      <h5 class="card-title">{{ item.name }}</h5>
                      <span class="card-subtitle text-muted">{{ item.jobTitle }} of {{ item.companyName }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <p class="card-text">{{ item.reference }}</p>
                </div>
              </div>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

import { TESTIMONIAL_CONTENT } from "@/constant/ContentConstant";

export default {
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    settings: {
      arrows: false,
      dots: true,
      dotsClass: "slick-dots slider-dots-custom",
      edgeFriction: 0.35,
      infinite: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      focusOnSelect: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
    testimonials: TESTIMONIAL_CONTENT,
  }),
};
</script>
