export const TESTIMONIAL_CONTENT = [{
    name: "John Lane",
    companyName: "Cartula International",
    jobTitle: "Executive Chairman",
    image: require("@/assets/images/img-testi-john-lane.jpg"),
    reference: `"Cartula International has used Nostratech as their chief technology partner since mid 2018. During that period they have worked with us to develop systems in use at major Australian government hospitals. Other patient-centric systems developed in conjunction with Nostratech have been deployed to many thousands of users in Indonesia and India.
    Nostratech has proven to be a cost effective partner capable of producing world class quality outcomes to demanding schedules. Importantly, beyond the technical partnership, the management of Nostratech has been able to provide valuable commercial insights into the Indonesian marketplace.All in all, we can unreservedly recommend the services of Nostratech to anyone needing a reliable, quality technology partner."`,
  },
  {
    name: "Vimal Prakash V",
    companyName: "Cartula Health India",
    jobTitle: "CEO",
    image: require("@/assets/images/img-testi-vimal.jpg"),
    reference: `"I have been working with Nostratech from last 2 years, 
      they have developed our two apps end to end. 
      We are now live with maternity app called Cartula Janani 
      and also going live with telemedicine app, called Cartula Health. 
      Both the apps are very much appreciated by our users and one of the best apps in the market. 
      Working with Ronny and his team is amazing, In simple words, 
      Nostratech is the perfect combination of Skills, honesty and integrity. 
      I personally see Nostratec as our Business partner for not only our current project but for all our upcoming projects in 6 countries."`,
  },
  {
    name: "Maskaulina Sinuhaji",
    companyName: "FIFGROUP",
    jobTitle: "CRM Data Mining Department Head",
    image: require("@/assets/images/img-testi-maska.jpg"),
    reference: `"Nostra membantu perusahaan kami membangun aplikasi distribusi data potensi ke cabang cabang. Dalam prosesnya, team Nostra yang menangani project kami memiliki komposisi orang dengan kualitas baik yaitu memiliki kemampuan teknis dan komunikasi yang baik."`,
  },
];

export const AVAILABLE_POSITIONS = [{
    position: "Backend Engineer",
    time: "Full Time",
    team: "Engineering Team",
    description: "We’re looking for a proactive, team oriented and motivated Backend Engineer to join our team. We value a go-getter attitude, the drive to get things done, and the empathy to understand our client’s needs and wants. Our team prides itself on working hard and having fun while doing it. We believe in the power of bringing like-minded people together.",
    url: "/career/backend-engineer"
  },
  {
    position: "Frontend Engineer",
    time: "Full Time",
    team: "Engineering Team",
    description: "We’re looking for a proactive, team oriented and motivated Frontend Engineer to join our team. We value a go-getter attitude, the drive to get things done, and the empathy to understand our client’s needs and wants. Our team prides itself on working hard and having fun while doing it. We believe in the power of bringing like-minded people together.",
    url: "/career/frontend-engineer"
  },
  {
    position: "Android Engineer",
    time: "Full Time",
    team: "Engineering Team",
    description: "We’re looking for a proactive, team oriented and motivated Android Engineer to join our team. We value a go-getter attitude, the drive to get things done, and the empathy to understand our client’s needs and wants. Our team prides itself on working hard and having fun while doing it. We believe in the power of bringing like-minded people together.",
    url: "/career/android-engineer"
  },
  {
    position: "iOS Engineer",
    time: "Full Time",
    team: "Engineering Team",
    description: "We’re looking for a proactive, team oriented and motivated iOS Engineer to join our team. We value a go-getter attitude, the drive to get things done, and the empathy to understand our client’s needs and wants. Our team prides itself on working hard and having fun while doing it. We believe in the power of bringing like-minded people together.",
    url: "/career/ios-engineer"
  },
  {
    position: "Devops Engineer",
    time: "Full Time",
    team: "Engineering Team",
    description: "We’re looking for a proactive, team oriented and motivated Devops Engineer to join our team. We value a go-getter attitude, the drive to get things done, and the empathy to understand our client’s needs and wants. Our team prides itself on working hard and having fun while doing it. We believe in the power of bringing like-minded people together.",
    url: "/career/devops-engineer"
  }
]